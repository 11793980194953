<template>
  <div class="about">
    <swiper :banner-list="bannerList"></swiper>
    <div class="content">
      <div class="aboutBox">
        <div class="left" v-loading="!aboutInfo.length > 0">
          <el-image
            style="width: 123px; height: 160px;padding-bottom: 10px;"
            v-for="(item, index) in aboutInfo"
            :key="index"
            :src="item.image"
            fit="cover"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img
            v-for="(item, index) in aboutInfo"
            :key="index"
            :src="item.image"
            alt=""
          /> -->
        </div>
        <div class="right">
          <div class="title">
            <span>{{ i18nData.title1 || "About us" }}</span>
            <div></div>
          </div>
          <div
            class="introduce"
            v-loading="!pageInfo.content"
            v-html="pageInfo.content"
          ></div>
        </div>
      </div>
      <div class="interests">
        <div class="title">
          {{ i18nData.title2 || "The current research interests" }}
        </div>
        <div class="showImg" v-loading="!aboutImg.length > 0">
          <el-image
            style="width: 233px; height: 282px;"
            v-for="(item, index) in aboutImg"
            :key="item.id"
            :index="index"
            :src="item.image"
            fit="cover"
            lazy
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img
            v-for="(item, index) in aboutImg"
            :key="item.id"
            :index="index"
            :src="item.image"
            alt=""
          /> -->
          <!-- <img src="./../../public/img/about/intesent_img1.png" alt="" />
          <img src="./../../public/img/about/intesent_img2.png" alt="" />
          <img src="./../../public/img/about/intesent_img1.png" alt="" /> -->
        </div>
        <div class="project">
          <div class="title">
            Successfully applied in practical engineering project
          </div>
          <div class="itemBox" v-loading="!projectList.length > 0">
            <div
              class="item"
              v-for="(item, index) in projectList"
              :key="item.id"
            >
              <span>{{ index + 1 }}</span>
              <span v-html="item.content"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="title">
          <span> {{ i18nData.title3 || "OUR Group" }}</span>
          <div></div>
        </div>
        <div class="groupImg" v-loading="!ourgroupimg.length > 0">
          <el-image
            style="width: 100%; min-height: 200px;"
            v-for="(item, index) in ourgroupimg"
            :key="item.id"
            :index="index"
            :src="item.image"
            fit="cover"
            lazy
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img
            v-for="(item, index) in ourgroupimg"
            :key="index"
            :src="item.image"
            alt=""
          /> -->
        </div>
        <div class="groupLeaderBox" v-loading="!researchTeam.length > 0">
          <div
            class="groupLeader"
            v-for="(item, index) in researchTeam"
            :key="item.id"
            :index="index"
          >
            <div class="left">
              <el-image
                style="width: 100%; min-height: 200px;"
                :src="item.image || require('./../../public/img/about/portrait.png')"
                fit="cover"
                lazy
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <!-- <img
              :src="item.image || './../../public/img/about/portrait.png'"
              alt=""
            /> -->
            </div>
            <div class="right">
              <div class="title">
                <span>{{ item.title || "Liu Wen" }}</span>
                <div></div>
              </div>
              <div class="introduce" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "./../components/swiper.vue";

export default {
  name: "about",
  components: {
    swiper
  },
  data() {
    return {
      pageInfo: {},
      bannerList: [],
      researchTeam: [],
      aboutInfo: [
        // {
        //   id: 1,
        //   image: require("./../../public/img/about/about_img1.png")
        // },
        // {
        //   id: 2,
        //   image: require("./../../public/img/about/about_img2.png")
        // },
        // {
        //   id: 3,
        //   image: require("./../../public/img/about/about_img3.png")
        // },
        // {
        //   id: 4,
        //   image: require("./../../public/img/about/about_img4.png")
        // }
      ],
      aboutImg: [
        {
          id: 1,
          image: require("./../../public/img/about/intesent_img1.png")
        },
        {
          id: 2,
          image: require("./../../public/img/about/intesent_img2.png")
        },
        {
          id: 3,
          image: require("./../../public/img/about/intesent_img1.png")
        }
      ],
      projectList: [
        {
          id: 1,
          content:
            "By the help of Beijing Orient Green Water Science and Technology Ltd., our group has built up a production line for Ti-C functional materials."
        },
        {
          id: 2,
          content:
            "We have established a good cooperative relationship with listed environmental companies, such as Haitian Water Group Co., Ltd and Yonker Environmental Protection Co,. Ltd"
        },
        {
          id: 3,
          content:
            "Supported by Peking University Special Project for COVID-19 Prevention and Control, we developed solar-light-driven photocatalysts spray, which can achieve virus disinfection in public place"
        }
      ],
      ourgroupimg: [
        {
          id: 1,
          image: require("./../../public/img/about/group_img.jpg")
        }
      ]
    };
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getAbout();
    this.getContent();
  },
  methods: {
    async getContent() {
      try {
        let params = {
          name:'aboutpc'
        }
        let res = await this.$api.https.getpages(params);
        // let res = await this.$api.https.getMyboutus();
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.pageInfo = res.data.pageInfo;
      } catch (err) {
        console.log(err);
      }
    },
    // 获取页面信息
    async getAbout() {
      // 获取swiper
      this.bannerList = await this.getblocklist("aboutswiper");
      // 获取about左边图片信息
      this.aboutInfo = await this.getblocklist("aboutInfo");
      // 获取about详情图片信息
      this.aboutImg = await this.getblocklist("aboutimg");
      // 获取实际工程项目信息
      this.projectList = await this.getblocklist("projectlist");
      // 获取我们的团队
      this.ourgroupimg = await this.getblocklist("ourgroupimage");
      // 获取团队信息
      this.researchTeam = await this.getblocklist("researchTeam");
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin title($width) {
  .title {
    text-align: center;
    height: 40px;
    font-size: 36px;
    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -15px 0 0 0;
    }
  }
}
@mixin introduce() {
  .introduce {
    width: 100%;
    min-height: 240px;
    margin-top: 20px;
    line-height: 24px;
    color: #676767;
  }
}
$vue_color: #9b0000;
.about {
  background: #ffffff;
  text-align: left;
  color: #282828;
  font-size: 12px;
  .content {
    padding: 20px 60px;
    .aboutBox {
      display: flex;
      padding: 0 0 50px;
      .left {
        padding: 0 30px 0 0;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        width: 256px;
        ::v-deep img {
          width: 123px;
          height: 160px;
          padding-bottom: 10px;
          transition: 0.3s;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include title($width: 189px);
        @include introduce;
      }
    }
    .interests {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .title {
        background-color: #0b6fbc;
        width: 618px;
        height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        color: #ffffff;
      }
      .showImg {
        padding: 50px 0;
        width: 100%;
        min-height: 282px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 233px;
          height: 282px;
        }
      }
      .project {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 40px;
        flex-direction: column;
        background-color: #cbe8ff;
        border-radius: 5px;
        .title {
          text-align: center;
          font-size: 18px;
          color: #0b6fbc;
        }
        .itemBox {
          margin: 40px 0 0 0;
          .item {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 16px 16px;
            border-radius: 5px;
            background-color: #ffffff;
            margin: 0 0 16px;
            span:nth-child(1) {
              color: #cbe8ff;
              font-size: 48px;
              padding: 0 16px 0 0;
            }
            span:nth-child(2) {
              flex: 1;
              line-height: 24px;
            }
          }
        }
      }
    }
    .group {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include title($width: 218px);
      .groupImg {
        box-sizing: border-box;
        padding: 0 40px 60px;
        img {
          width: 100%;
        }
      }
      .groupLeaderBox {
        min-height: 240px;
        .groupLeader {
          display: flex;
          .left {
            flex: 3;
            padding: 0 30px 0 0;
            img {
              width: 100%;
            }
          }
          .right {
            flex: 9;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include title($width: 189px);
            @include introduce;
          }
        }
      }
    }
  }
}
</style>
